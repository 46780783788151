<template>
  <b-card-code
    no-body
    title="Scanner Allocations"
  >
    <button @click="addnew()" type="button" class="btn ml-auto btn-primary m-1">
      Add New
    </button>

    <b-table
      responsive="sm" id="allocTable" :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(actions)="data">
        <!-- <button @click="editOrg(data.item.org_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteOrg(data.item.org_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button> -->
        <b-dropdown
            size="sm"
            class="ml-1"
            variant="outline-primary"
            text="Actions"
        >
            <b-dropdown-item @click="editAlloc(data.item.a_id)">Edit</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="deleteAlloc(data.item.a_id)">Delete</b-dropdown-item>
            
        </b-dropdown>
      </template>
    </b-table>

   <b-pagination
    v-model="currentPage"
    :total-rows="rows"
    :per-page="perPage"
    :align="pagination_pos"
    aria-controls="allocTable"
  />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import { BPagination,BPaginationNav, BButton,BDropdown, BDropdownItem, BDropdownDivider, } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown, BDropdownItem, BDropdownDivider,
  },
  data() {   
    return {
      pagination_pos: 'center',
    // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows:0,
      fields: [
        { key: 'a_id', label: '#' },
        { key: 'agent.agent_name', label: 'Name' },
        { key: 'agent.agent_type', label: 'Agent Type' },
        { key: 'is_default', label: 'Default' },
        { key: 'asset_type.asset_type_name', label: 'Asset Type' },
        { key: 'actions', label: 'Actions' }
      ],
    }
  },
  created: function () {
                this.load();
            },
  methods: {
    load: function () {
        const options = {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            url: process.env.VUE_APP_BASEURL+'agent/allocation?org_id='+this.$route.params.id,
          }
          this.$http(options).then( ( res ) => {
              console.log(res.data)
            // this.toastMessage(res)  // method call to method2 
              this.items = res.data;
              this.rows = res.data.length;
            //   this.currentPage = 1
            //   this.perPage = 10
            //   this.rows = res.data.count
            } )
    },
    addnew(){
      this.$router.push({name: 'Allocate New Scanner'});
    },
    editAlloc(id){
      this.$router.push({name: 'Edit Allocation', params: { id: id}});
    },
    deleteAlloc(id){
        if(confirm("Do you really want to delete?")){
            const options = {
              method: 'DELETE',
              headers: { 'content-type': 'application/json' },
              url: process.env.VUE_APP_BASEURL+'agent/allocation/'+id+"/",
          }
          this.$http(options).then( ( res ) => {
            console.log(res)
            this.load();
          })          
        }
    },

  },

}
</script>
